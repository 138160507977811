import { Component, OnInit } from '@angular/core';
import { HttpService } from './../services-module/http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';


@Component({
  selector: 'app-remove',
  templateUrl: './remove.component.html',
  styleUrls: ['./remove.component.scss']
})
export class RemoveComponent implements OnInit {

  id
  page

  pageElement

  confirm = false;

  feedback = false;

  success = false;

  error = false

  constructor(
    private route: ActivatedRoute,
    private service:HttpService,
    private location: Location,
    private router: Router


  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.page = this.route.snapshot.paramMap.get('page');


    this.service.readID(`${this.page}/${this.id}`)
    .subscribe(res =>
      this.pageElement = res
      )
  }

  deletePost() {
  
    return this.confirm = true;
    
  }
  
  confirmDelete(){
    this.service.remove(`${this.page}/${this.id}`).subscribe(res => {
      this.feedback = true
      this.router.navigateByUrl('refresh')

    });   
    this.confirm = false;
    this.success = true;
  }
  
    cancel(){
      this.location.back(); 
    }
  
    back(){
      this.location.back(); 
    }
  


}
