<div
class="modal fade"
id="editModal"
tabindex="-1"
role="dialog"
aria-labelledby="editModalTitle"
aria-hidden="true"
data-backdrop="false"
>
<div
  class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
  role="document"
>
  <div class="modal-content">
    <div class="modal-header">
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="back()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
</div>