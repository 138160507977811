<div [@pageLoad]="pageState">
  <div class="py-5 text-center text-white h-60 align-items-center d-flex">
    <div class="container py-5">
      <div class="row">
        <div class="mx-auto col-lg-8 col-md-10">
          <h1 class="display-3 mb-4">Serviços</h1>
        </div>
      </div>
    </div>
  </div>

  <section class="container py-5">
    <h2 class="display-4 py-3">Excelência em serviços</h2>
    <div class="row" >
      <div
        class="col-md-6 col-12 d-flex m-0 p-0"
        data-aos="fade-up"
        data-aos-delay="650"
        data-aos-duration="1000"
        *ngFor="let item of pageElement"
      >
        <div class="mt-4 mt-md-0 service d-flex flex-column p-3 m-1 my-2" [class.editable]='edit' >
          <img
            class="img-fluid d-block"
            src="../../assets/arpron/assets/{{item.image}}"
          />
          <h3 class="my-3">
            <i class="pr-2 flaticon-wrench"></i><b>{{ item.caption }}</b>
          </h3>
          <div
            class="service-wrapper d-flex flex-column justify-content-between"
          >
              <p>
                {{item.text}}
              </p>

          </div>
          <div class="d-flex justify-content-around editable-buttons">
            <a
            class="btn btn-primary text-uppercase text-white"
            [routerLink]="['/edit', 'facilities', item.id]"
            data-toggle="modal"
            data-target="#editModal"
            >Editar</a
          >
          <a
          class="btn btn-danger text-uppercase text-white"
          [routerLink]="['remove', 'facilities', item.id]"
          data-toggle="modal"
          data-target="#editModal"
          >Remover</a
        >
          </div>
        </div>
      </div>


    </div>
    <div class="row py-3" *ngIf="edit" >
      <div class="col text-center">
        <a
          class="btn btn-primary text-white btn-rounded"
          data-toggle="modal"
          data-target="#editModal"
          [routerLink]="['add', 'facilities']"
          >
              Adicionar Serviço
          </a
        >
      </div>
    </div>
  </section>

  <section class="container-fluid">
    <app-brands></app-brands>
    <app-contact></app-contact>
  </section>
</div>
<app-modal></app-modal>

