import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { HttpService } from '../services-module/http.service';

@Component({
  selector: 'app-auth-form',
  templateUrl: './auth-form.component.html',
  styleUrls: ['./auth-form.component.scss']
})
export class AuthFormComponent implements OnInit {

  @Output() log = new EventEmitter()
  edit
  
  constructor(
    private service: HttpService,
    private router: Router,
    private location: Location

  ) { }

  ngOnInit(): void {
    this.edit = this.service.edit
  }


  logIn(){
    this.service.edit = true
    this.edit = true
    this.logEmit()
    this.router.navigateByUrl('refresh')
  }

  logEmit(){
    this.log.emit(this.edit)
  }


  back(){
    this.location.back()
  }


}
