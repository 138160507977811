<nav class="navbar navbar-expand-md navbar-light" [ngClass]="nav">
  <div class="container-fluid">
    <a class="navbar-brand text-primary" [routerLink]="['']">
      <img src="../assets/arpron/assets/logo-arpron.svg" class="img-fluid" alt="">
    </a>
    <button class="navbar-toggler navbar-toggler-right border-0" type="button" data-toggle="collapse"
      data-target="#navbar4">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbar4">
      <ul class="navbar-nav ml-auto" data-toggle="collapse" data-target="#navbar4.show">
        <li class="nav-item"><a class="nav-link" [routerLink]="['']">Home</a></li>
        <li class="nav-item"><a class="nav-link" [routerLink]="['/produtos']">Produtos</a></li>
        <li class="nav-item"><a class="nav-link" [routerLink]="['/servicos']">Serviços</a></li>
        <li class="nav-item"><a class="nav-link" [routerLink]="['/quem-somos']">Quem somos</a></li>
      </ul>
      <a class="d-inline-flex d-md-block nav-link btn navbar-btn ml-md-2 text-white btn-primary"
      (click)="goToContact()"
       >Contato</a>
    </div>
  </div>
</nav>
<router-outlet></router-outlet>
<footer class="py-3">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-sm-6 p-3">
        <img src="../assets/arpron/assets/logo-arpron.svg" 
        alt="logo arpron" class="logo-img">
      </div>
      <div class="col-lg-3 col-sm-6 p-3">
        <h5><b>Contato</b></h5>
        <ul class="list-unstyled">
          <li><a class="text-decoration-none" href="tel:+551147432942">Fone: 11 4743 2942</a></li>
          <li><a class="text-decoration-none" href="https://web.whatsapp.com/send?phone=5511974703535">Whatsapp: 11 97470 3535</a></li>
          <li><a class="text-decoration-none" href="tel:+5511974703535">Cel: 11 97470 3535</a></li>

          <li><a class="text-decoration-none" href="">Email: arpron@grupoarpron.com.br</a></li>
        </ul>
      </div>
      <div class="col-lg-3 col-md-6 p-3">
        <h5><b>Endereço</b></h5>
        <p class="mb-0">Rua Capitão Pedro Esperidião Hoffer, 529 - Jd. Nova Poá - Poá - SP</p>
        <h5><b class="pointer" [routerLink]="['/trabalhe-conosco']">Trabalhe Conosco</b></h5>
      </div>
      <div class="col-lg-3 col-md-6 p-3">
        <h5><b>Siga nossas redes sociais</b></h5>
        <div class="row">
          <div class="col-md-12 d-flex align-items-center justify-content-around my-2">
            <a href="">
              <i class="fab fa-facebook-square text-decoration-none"></i>
            </a>
            <a href="">
              <i class="fab fa-google-plus-g text-decoration-none"></i>
            </a>
            <a href="">
              <i class="fab fa-linkedin-in text-decoration-none"></i>
            </a>
            <a href="https://web.whatsapp.com/send?phone=5511974703535">
              <i class="fab fa-whatsapp text-decoration-none"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-center">
        <p class="mb-0 mt-2">©2020. Todos os direitos reservados. <a data-toggle="modal" data-target="#LoginModal" *ngIf='!edit'>Login</a><a (click)="logOut()" *ngIf='edit'>Logout</a></p>
      </div>
    </div>
  </div>
</footer>

<div class="modal fade" id="LoginModal" tabindex="-1" role="dialog" aria-labelledby="editModalTitle" aria-hidden="true"
  data-backdrop="false">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <app-auth-form (log)="logEvent($event)"></app-auth-form>
    </div>
  </div>
</div>