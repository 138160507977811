import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ConstructionComponent } from './construction/construction.component';
import { AboutComponent } from './about/about.component';
import { FacilitiesComponent } from './facilities/facilities.component';
import { ProductsComponent } from './products/products.component';
import { ContactComponent } from './contact/contact.component';
import { PartnersComponent } from './partners/partners.component';
import { ProjectsHighlightsComponent } from './projects-highlights/projects-highlights.component';
import { BrandsComponent } from './brands/brands.component';
import { FeaturedProjectComponent } from './featured-project/featured-project.component';
import { EditComponent } from './edit/edit.component';
import { ModalComponent } from './modal/modal.component';
import { RefreshComponent } from './refresh/refresh.component';
import { AddComponent } from './add/add.component';
import { RemoveComponent } from './remove/remove.component';
import { AuthFormComponent } from './auth-form/auth-form.component';
import { WorkWithUsComponent } from './work-with-us/work-with-us.component';
import { SaleComponent } from './sale/sale.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ConstructionComponent,
    AboutComponent,
    FacilitiesComponent,
    ProductsComponent,
    ContactComponent,
    PartnersComponent,
    ProjectsHighlightsComponent,
    BrandsComponent,
    FeaturedProjectComponent,
    EditComponent,
    ModalComponent,
    RefreshComponent,
    AddComponent,
    RemoveComponent,
    AuthFormComponent,
    WorkWithUsComponent,
    SaleComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    SlickCarouselModule,
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
