import { Component, OnInit } from '@angular/core';
import { HttpService } from './../services-module/http.service';

@Component({
  selector: 'app-projects-highlights',
  templateUrl: './projects-highlights.component.html',
  styleUrls: ['./projects-highlights.component.scss']
})
export class ProjectsHighlightsComponent implements OnInit {

  edit = false

  
  pageElement = null

  page = 'projects-highlights'

  element=null


  slideConfig = {
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 1000,
    infinite: true,
    arrows: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      }
    ],
  };

  constructor(
    private service: HttpService
  ) { }

  ngOnInit(): void {
    this.edit = this.service.edit
    this.service.loadData(this.page)
    .subscribe( res =>
      this.pageElement = res

      );

    

    }

    changeID(id){
      this.element = `${this.page}/${id}`    
    }




}
