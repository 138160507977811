import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  animations: [
    trigger('pageLoad', [
      state('ready', style({opacity: 1})),
      transition('void => ready', [
      style({opacity: 0}),
      animate('600ms ease-in-out')
      ])
    ])
  ],
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  pageState = 'ready';
  
  constructor() { }

  ngOnInit(): void {
  }

}
