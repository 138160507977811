  <div class="py-5 brands">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <span>Somos credenciados nas melhores</span>
          <h2 class="py-4">Marcas</h2>
          <ngx-slick-carousel
            class="carousel"
            #slickModal="slick-carousel"
            [config]="slideConfig"
          >
            <div
              class="d-flex flex-column justify-content-center align-items-center"
              ngxSlickItem
              *ngFor="let item of slides"
            >

              <img
                src="../../assets/arpron/assets/{{ item.slide }}"
                alt=""
                class="img-fluid"
              />
            </div>
          </ngx-slick-carousel>
        </div>
      </div>
    </div>
  </div>
