<div class="pt-5 projects">
  <div class="container-fluid pt-5">
    <div class="container">
      <div class="row">
        <div class="text-right mx-auto col-md-12">
          <span
            data-aos="fade-left"
            data-aos-delay="300"
            data-aos-duration="1500"
            >Em destaque</span
          >
          <h2 class="">Projetos</h2>
        </div>
      </div>
    </div>

    <div
      class="row pt-5 text-center align-items-end"
      data-aos="fade-in"
      data-aos-delay="500"
      data-aos-duration="1500"
    >
      <div class="col">
        <ngx-slick-carousel
          class="carousel"
          #slickModal="slick-carousel"
          [config]="slideConfig"
          data-toggle="modal"
          data-target="#modalProjects"
        >
          <div *ngFor="let item of pageElement">
            <div ngxSlickItem>
              <div class="img-container">
                <img
                  class="img-fluid"
                  src="../../assets/arpron/assets/{{ item.image }}"
                />
                <a data-target="#galleryModal" data-slide-to="0">
                  <div
                    class="img-overlay p-2 text-center text-decoration-none d-flex flex-column justify-content-center align-items-center"
                  >
                    <h6 class="text-uppercase">{{ item.caption }}</h6>
                    <p
                      class="pt-5 text-white text-decoration-none"
                      *ngIf="!edit"
                    >
                      zoom
                    </p>
                    <button
                      class="btn btn-warning btn-ac-update text-white editable-button text-uppercase text-bold"
                      data-toggle="modal"
                      data-target="#editModal"
                      [routerLink]="['/edit', 'projects-highlights', item.id]"
                      *ngIf="edit" 
                    >
                      Editar
                    </button>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </ngx-slick-carousel>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div
  class="modal fade"
  id="modalProjects"
  tabindex="-1"
  role="dialog"
  aria-labelledby="modalProjects"
  aria-hidden="true"
  *ngIf="!edit"
>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-body" *ngIf="pageElement">
        <div id="galleryModal" class="carousel slide" data-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img
                class="d-block w-100"
                src="../../assets/arpron/assets/{{ pageElement[0].image }}"
              />
            </div>
            <div class="carousel-item">
              <img
                class="d-block w-100"
                src="../../assets/arpron/assets/{{ pageElement[1].image }}"
              />
            </div>
            <div class="carousel-item">
              <img
                class="d-block w-100"
                src="../../assets/arpron/assets/{{ pageElement[2].image }}"
              />
            </div>
            <div class="carousel-item">
              <img
                class="d-block w-100"
                src="../../assets/arpron/assets/{{ pageElement[3].image }}"
              />
            </div>
            <div class="carousel-item">
              <img
                class="d-block w-100"
                src="../../assets/arpron/assets/{{ pageElement[4].image }}"
              />
            </div>
          </div>
          <a
            class="carousel-control-prev"
            href="#galleryModal"
            role="button"
            data-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a
            class="carousel-control-next"
            href="#galleryModal"
            role="button"
            data-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<app-modal></app-modal>
