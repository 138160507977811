<div class="py-5 bg-light contact" id='contact'>
  <div class="container">
    <!-- <div class="row">
        <div class="mx-auto text-center col-lg-6">
          <h2 class="mb-3 py-3">Faça um orçamento</h2>
          <p class="lead mb-4">
            Nossa equipe está pronta para tirar suas dúvidas
          </p>
        </div>
      </div> -->
<div class="row">
  <div
    class="py-4 p-lg-0 order-2 col col-lg-4"
    data-aos="fade-right"
    data-aos-delay="300"
    data-aos-duration="1500"
  >
    <iframe
      width="100%"
      height="440"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3657.408336837895!2d-46.35078268438636!3d-23.55377366719851!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce7aa4ae749747%3A0x182725eb18b796cc!2sR.%20Cap.%20Pedro%20Esperidi%C3%A3o%20Hoffer%2C%20529%20-%20Jardim%20Nova%20Poa%2C%20Po%C3%A1%20-%20SP%2C%2008568-700!5e0!3m2!1spt-BR!2sbr!4v1618070025585!5m2!1spt-BR!2sbr"
      scrolling="no"
      frameborder="0"
    ></iframe>
  </div>

  <div
    class="px-4 order-1 order-lg-2 col-lg-4"
    data-aos="fade-up"
    data-aos-delay="300"
    data-aos-duration="1500"
  >
    <h3 class="mb-4">Cálculo de carga térmica/btu</h3>
 
    <form *ngIf="!resultOfCalculatedBtu">
    <div class="form-group">
      <label for="area-size">
        Ambiente <small>Total da área em m² (Exemplo 3.80)</small>
        <input
          type="text"
          placeholder="0"
          defaulValue="0"
          class="form-control"
          id="form44"
          name="area-size"
          #areaSize
        />
      </label>
    </div>
    <div class="form-group">
      <label for="people-number">
        Número de pessoas <small>Total de pessoas máximo no ambiente</small>
        <input
          type="text"
          placeholder="0"
          defaulValue="0"
          class="form-control"
          id="form44"
          name="people-number"
          #peopleCount
        />
      </label>
    </div>
    <div class="form-group">
      <label for="computers">
        Eletrônicos <small>Total de computadores</small>
        <input
          type="text"
          placeholder="0"
          defaulValue="0"
          class="form-control"
          id="form44"
          name="computers"
          #computers
        />
      </label>
    </div>
    <div *ngIf="feedbackMessage" class="alert alert-danger" role="alert">
      {{ feedbackMessage }}
    </div>
    <div *ngIf="!resultOfCalculatedBtu" class="row justify-content-center">
      <div class="col-12 buttons-wrapper">
        <button type="button" class="btn btn-blue" (click)="calculateBTU(areaSize, peopleCount, computers)">
          Calcular
        </button>
        <!-- <a href="https://web.whatsapp.com/send?phone=5511974703535" type="button" class="btn btn-success"><i class="fab fa-whatsapp"></i>Fale com um consultor</a> -->
       </div>
    </div>
    
  </form>
    <div class="row justify-content-center" *ngIf="resultOfCalculatedBtu && !feedbackMessage">
      <div  class="alert alert-info" role="alert">
        {{ resultOfCalculatedBtu }}
      </div>
      <div class="col-12 buttons-wrapper">
        <!-- <a href="https://web.whatsapp.com/send?phone=5511974703535" type="button" class="btn btn-success m-auto"><i class="fab fa-whatsapp"></i>Fale com um consultor</a> -->
        <span (click)="resetResult()" >Calcular novamente</span>
      </div>
    </div>
  </div>
  <div
  class="px-4 order-1 order-lg-2 col-lg-4"
  data-aos="fade-left"
  data-aos-delay="300"
  data-aos-duration="1500"
  >
  <h3 class="mb-4">Fale conosco</h3>
      <form>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            id="form64"
            placeholder="Nome"
          />
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            id="form65"
            placeholder="Telefone"
          />
        </div>
        <div class="form-group">
          <input
            type="email"
            class="form-control"
            id="form66"
            placeholder="Email"
          />
        </div>
        <div class="form-group">
          <textarea
            class="form-control"
            id="form66"
            rows="6"
            placeholder="Mensagem"
          ></textarea>
        </div>
        <div class="buttons-wrapper"><button type="submit" class="btn .btn-blue btn-primary">Enviar</button></div>
    </form>
    </div>
</div>

</div>
 
 