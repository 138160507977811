<div [@pageLoad]="pageState" id="homepage" >
    <div>
      <div class="row carousel-wrapper">
        <div class="col-md-12">
          <div
            id="carouselExampleIndicators"
            class="carousel slide carousel-fade"
            data-ride="carousel"
          >
            <ol class="carousel-indicators d-none d-sm-flex">
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="0"
                class="active"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="1"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="2"
              ></li>
            </ol>
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img
                  class="d-block img-fluid w-100"
                  src="../../assets/arpron/assets/maintenance-2.jpg"
                />
                <div
                  class="d-none d-md-block col-md-4 py-5 my-5 carousel-caption text-left"
                >
                  <span>01</span>
                  <h1 class="display-3">Vendas</h1>
                  <p class="text-uppercase">
                    Os melhores equipamentos
                  </p>
                  <a class="btn btn-primary"
                  (click)="goToContact()" 
                  >Faça um orçamento</a
                  >
                </div>
              </div>
              <div class="carousel-item">
                <img
                  class="d-block img-fluid w-100"
                  src="../../assets/arpron/assets/maintenance.png"
                />
                <div
                  class="d-none d-md-block col-md-4 py-5 my-5 carousel-caption text-left"
                >
                  <span>02</span>
                  <h1 class="display-3">Instalação e manutenção</h1>
                  <p class="text-uppercase">
                    Instalação e manutenção de sistenas de climatização
                  </p>
                  <a class="btn btn-primary"
                  (click)="goToContact()" 
                  >Faça um orçamento</a
                  >
                </div>
              </div>
              <div class="carousel-item">
                <img
                  class="d-block img-fluid w-100"
                  src="../../assets/arpron/assets/camara-frigorifica.jpg"
                />
                <div
                  class="d-none d-md-block col-md-4 py-5 my-5 carousel-caption text-left"
                >
                  <span>03</span>
                  <h1 class="display-3">Projetos PMOC</h1>
                  <p class="text-uppercase">                  
                    Projetos Industriais, comerciais e residenciais
                  </p>
                  <a class="btn btn-primary"
                  (click)="goToContact()" 
                  >Faça um orçamento</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  <div class="spliter">
    <div></div>
  </div>

  <div class="text-center cards bg-light-blue">
    <div class="container">
      <div class="py-4">
        <ngx-slick-carousel
          class="customer-carousel py-3"
          #slickModal="slick-carousel"
          [config]="slideCards"
        >
          <div
            class="d-flex justify-content-center"
            ngxSlickItem
            *ngFor="let card of introCards"
          >
            <div
              class="card border-0 rounded-1 d-flex flex-column justify-content-center"
            >
              <div class="pt-4 card-wrapper m-0">
                <i class="{{ card.icon }}"></i>
                <h3 class="px-4">{{ card.title }}</h3>
                <div class="card-text-wrapper px-4 pb-4 mt-4">
                  <p class="mb-0 mx-0 py-4">
                    {{ card.body }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </ngx-slick-carousel>
      </div>
    </div>
  </div>

  <div class="py-5 intro bg-light-blue">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="row justify-content-center align-items-center">
            <div class="order-2 order-md-1 p-3 col-md-3">
              <img
                class="img-fluid d-block"
                src="../../assets/arpron/assets/Ibama.png"
              />
            </div>
            <div
              class="d-flex flex-column justify-content-center align-items-start order-1 order-md-2 p-3 col-md-9"
            >
              <h1 class="py-3">Somos a Arpron</h1>
              <p class="mb-3">
                Estamos sempre prontos para vencermos novos desafios, sejam eles
                nossos ou de nossos clientes, sempre compromissados com um ótimo
                resultado final.
              </p>
              <a class="btn btn-primary" [routerLink]="['/quem-somos']"
                >Conheça nossa história</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="pt-5 text-left title-services"
  >
    <div class="container-fluid" >
      <div class="container">
        <div class="row pt-5">
          <div class="col-md-12">
            <span>Nossos</span>
            <h2 class="py-3">Serviços</h2>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="py-5 services">
    <div class="container">
      <div class="row">
        <div
          class="col-md-6 col-lg-4 col-12 d-flex"
          data-aos="fade-up"
          data-aos-delay="500"
          data-aos-duration="1000"
        >
          <div class="mt-4 mt-md-0 m-1 p-2 service d-flex flex-column">
            <img
              class="img-fluid d-block img-wrapper"
              src="../../assets/arpron/assets/residencia.jpg"
            />
            <h3 class="my-3">
              <i class="flaticon-internet pr-2"></i><b>Residencial</b>
            </h3>
            <div
              class="service-wrapper d-flex flex-column justify-content-between"
            >
              <ul class="list-group list-group-flush">
                <li class="list-group-item">Split</li>
                <li class="list-group-item">Multisplit</li>
                <li class="list-group-item">Vrv</li>
                <li class="list-group-item">Vrf</li>
                <li class="list-group-item">Portáteis</li>
              </ul>
              <a (click)="goToContact()" class="btn bg-light p-3">Faça um orçamento</a>
            </div>
          </div>
        </div>

        <div
          class="col-md-6 col-lg-4 col-12 d-flex"
          data-aos="fade-up"
          data-aos-delay="650"
          data-aos-duration="1000"
        >
          <div class="mt-4 mt-md-0 m-1 p-2 service d-flex flex-column">
            <img
              class="img-fluid d-block img-wrapper"
              src="../../assets/arpron/assets/comercial.jpg"
            />
            <h3 class="my-3">
              <i class="flaticon-shop pr-2"></i><b>Comercial</b>
            </h3>
            <div
              class="service-wrapper d-flex flex-column justify-content-between"
            >
              <ul class="list-group list-group-flush">
                <li class="list-group-item">Condicionadores de ar tipo split</li>
                <li class="list-group-item">Self</li>
                <li class="list-group-item">Fancoil</li>
                <li class="list-group-item">Camara fria</li>
                <li class="list-group-item">Implantação de pmoc</li>
                <li class="list-group-item">Projetos</li>

              </ul>
              <a (click)="goToContact()" class="btn bg-light p-3">Faça um orçamento</a>
            </div>
          </div>
        </div>

        <div
          class="col-md-6 col-lg-4 col-12 d-flex"
          data-aos="fade-up"
          data-aos-delay="750"
          data-aos-duration="1000"
        >
          <div class="mt-4 mt-md-0 m-1 p-2 service d-flex flex-column">
            <img
              class="img-fluid d-block img-wrapper"
              src="../../assets/arpron/assets/industrial.jpg"
            />
            <h3 class="my-3">
              <i class="flaticon-power-plant pr-2"></i><b>Industrial</b>
            </h3>
            <div
              class="service-wrapper d-flex flex-column justify-content-between"
            >
              <ul class="list-group list-group-flush">
                <li class="list-group-item">Chiller</li>
                <li class="list-group-item">Self</li>
                <li class="list-group-item">Fancoil</li>
                <li class="list-group-item">Torres de resfriamento</li>
                <li class="list-group-item">Projetos</li>
                <li class="list-group-item">Implantação de pmoc</li>
                <li class="list-group-item">Alarmes de alta e baixa temperatura</li>
                <li class="list-group-item">Cálculo de carga térmica</li>
              </ul>
              <a (click)="goToContact()" class="btn bg-light p-3">Faça um orçamento</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container py-5">
    <div class="row align-items-center text-center">
      <div
        class="col-12 col-lg-4"
        data-aos="fade-right"
        data-aos-delay="300"
        data-aos-duration="1500"
      >
        <h2 class="display-4 text-lg-right">O ambiente certo</h2>
      </div>
      <div class="col-12 col-lg-4">
        <div
          class="nest text-center d-flex flex-column justify-content-center align-items-center"
        >
          <div
            class="celsius-counter d-flex flex-row justify-content-center align-items-center"
          >
            <h2 class="text-white display-4">
              <b> <span class="counter">23</span>°</b>
            </h2>
          </div>
        </div>
      </div>
      <div
        class="col-12 col-lg-4"
        data-aos="fade-left"
        data-aos-delay="700"
        data-aos-duration="1500"
      >
        <h2 class="h2 display-4 text-lg-left">na temperatura certa</h2>
      </div>
    </div>
  </div>
  <app-sale></app-sale>


  <app-projects-highlights></app-projects-highlights>
  <app-partners></app-partners>
  <app-contact id="#contact-form"></app-contact>
</div>
