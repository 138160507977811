<div class="py-5 partners bg-light-blue">
    <div class="container pt-5">
      <div class="row">
        <div
          class="text-left mx-auto col-md-12"
          data-aos="fade-right"
          data-aos-delay="300"
          data-aos-duration="1500"
        >
          <span>Veja o que dizem nossos</span>
          <h2 class="mb-4 py-3">Clientes</h2>
        </div>
      </div>

      <ngx-slick-carousel
        class="customer-carousel py-3"
        #slickModal="slick-carousel"
        [config]="slideConfig2"
      >
        <div ngxSlickItem *ngFor="let slide of slides">
          <div class="row">
            <div class="col">
              <div class="">
                <div class="p-4">
                  <div class="row">
                    <div
                      class="col-md-8 d-flex flex-column justify-content-center text-center"
                    >
                      <h4 class="">"{{ slide.testimonial }}"</h4>
                      <p class="lead">
                        <b>- {{ slide.owner }} </b>
                        <small class="text-muted">{{ slide.company }}</small>
                      </p>
                    </div>

                    <div
                      class="col-md-4 col-12 text-center d-flex flex-column justify-content-center align-items-center"
                    >
                      <img
                        class="img-fluid d-block rounded-circle"
                        src="{{ slide.img }}"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
