
<div [@pageLoad]="pageState">
    <div class="py-5 text-center text-white h-60 align-items-center d-flex">
      <div class="container py-5">
        <div class="row">
          <div class="mx-auto col-lg-8 col-md-10">
            <h1 class="display-3 mb-4">Trabalhe conosco</h1>
          </div>
        </div>
      </div>
    </div>
  
    <section class="container rounded py-5">
      <h3 class="display-5 py-3">Venha fazer parte do nosso time</h3>
      <div class="row bg-light p-5 b-5 form-wrapper" >
      <form>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            id="form64"
            placeholder="Nome"
          />
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            id="form65"
            placeholder="Telefone"
          />
        </div>
        <div class="form-group">
          <input
            type="email"
            class="form-control"
            id="form66"
            placeholder="Email"
          />
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            id="form67"
            placeholder="Profissão"
          />
        </div>
        <div class="form-group">
          <textarea
            class="form-control"
            id="form66"
            rows="10"
            placeholder="Mensagem"
          ></textarea>
        </div>
        <button type="submit" class="btn btn-primary">Enviar</button>
    </form>
  
      </div>
    </section>
  </div>
  <app-modal></app-modal>
  
  