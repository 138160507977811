import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  resultOfCalculatedBtu: string = ''
  feedbackMessage: string = ''

  constructor() { }

  ngOnInit(): void {
  }


  calculateBTU(areaSize, peopleCount, computers) {
    const calc = parseFloat(areaSize.value);
    const people = parseFloat(peopleCount.value);
    const comp = parseFloat(computers.value);
    let result = (calc * 600) + (comp * 600)
    if (areaSize.value === " " || peopleCount.value === "" || computers.value === " " || calc <= 0) {
      this.handleError()
      return
    } else {
      if ((people) > 2) { result = result + ((people - 2) * 600) };
    }

    this.resultOfCalculatedBtu =
      "De acordo com as informações que você forneceu, o aparelho de ar condicionado ideal para o seu ambiente deverá possuir no minimo: " + result + " BTU/h. Antes de comprar fale com um de nossos consultores para maiores detalhes";
  }

  handleError() {
    this.feedbackMessage = "Por favor, preencha todos os campos corretamente"
    setTimeout(() => {
      this.feedbackMessage = ''
    }, 5000);
  }

  resetResult() {
    this.resultOfCalculatedBtu = ''
  }

}
