import { Component, OnInit } from "@angular/core";


import { HttpService } from './../services-module/http.service';


import {
  trigger,
  state,
  style,
  transition,
  animate,
} from "@angular/animations";

declare const $: any;

@Component({
  selector: "app-products",
  templateUrl: "./products.component.html",
  animations: [
    trigger("pageLoad", [
      state("ready", style({ opacity: 1 })),
      transition("void => ready", [
        style({ opacity: 0 }),
        animate("600ms ease-in-out"),
      ]),
    ]),
  ],
  styleUrls: ["./products.component.scss"],
  preserveWhitespaces: true
})
export class ProductsComponent implements OnInit {
  pageState = "ready";

  edit = false

  page = 'products'
  pageElements = null

  constructor(
    private service: HttpService
  ) { }

  ngOnInit(): void {
    this.edit = this.service.edit
    this.service.url = 'produtos'
    this.service.loadData(this.page)
      .subscribe(res =>
        this.pageElements = res
      )
  }
}
