import { HttpService } from './../services-module/http.service';
import { Component, OnInit, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-facilities',
  templateUrl: './facilities.component.html',
  animations: [
    trigger('pageLoad', [
      state('ready', style({opacity: 1})),
      transition('void => ready', [
      style({opacity: 0}),
      animate('600ms ease-in-out')
      ])
    ])
  ],
  styleUrls: ['./facilities.component.scss']
})
export class FacilitiesComponent implements OnInit {
  pageState = 'ready';

  edit = false

  page = 'facilities'
  pageElement

  constructor(
    private service: HttpService
  ) { }

  ngOnInit(): void {
    this.edit = this.service.edit
    this.service.url = 'servicos'
    this.service.loadData(this.page)
    .subscribe( res =>
      this.pageElement = res  
      )
  }

}
