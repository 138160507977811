import { Component, OnInit, AfterViewInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from '../services-module/http.service';

@Component({
  selector: 'app-refresh',
  templateUrl: './refresh.component.html',
  animations: [
    trigger('pageLoad', [
      state('ready', style({opacity: 1})),
      transition('void => ready', [
      style({opacity: 0}),
      animate('600ms ease-in-out')
      ])
    ])
  ],
  styleUrls: ['./refresh.component.scss']
})
export class RefreshComponent implements OnInit, AfterViewInit {
  pageState='ready'

  url

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: HttpService
  ) { }

  ngOnInit(): void {
    this.url = this.service.url
  }

  ngAfterViewInit():void {
    this.navTo()
  }

  navTo(){
    this.router.navigateByUrl(`${this.url}`);
  }

}
