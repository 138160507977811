<div class="container">
    <p *ngIf="!pageElement">Carregando...</p>
    <div *ngIf="pageElement">
      <div *ngIf="!pageElement.typeImage && !pageElement.typePost">
        <input
          type="text"
          class="form-control input-content"
          [(ngModel)]="pageElement.caption"
          placeholder="Título"
        />
        <textarea
          cols="4"
          rows="5"
          class="form-control input-content"
          [(ngModel)]="pageElement.text"
          wrap="hard"
          placeholder="Texto"
        ></textarea>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            (click)="cancel()"
          >
            Fechar
          </button>
          <button
            data-dismiss="modal"
            type="button"
            class="btn btn-primary"
            (click)="updateContent()"
          >
            Salvar
          </button>
        </div>
      </div>
      <!--  -->
  
      <!-- Imagem -->
      <div *ngIf="pageElement.typeImage">
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col text-center">
              <img src='../../assets/arpron/assets/{{pageElement.image}}'  class="img-fluid" />
            </div>
          </div>  
          <div class="text-left">
              <input type="file" id="selectedFile2" style="display: none;" (change)="inputFileChange($event)"/>
              <input type="button" value="Imagem" onclick="document.getElementById('selectedFile2').click();" />
            </div>
            <input
            type="text"
            class="form-control input-content"
            [(ngModel)]="pageElement.caption"
            placeholder="Título"
          />
        </div>
  
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            (click)="cancel()"
          >
            Fechar
          </button>
          <button
            data-dismiss="modal"
            type="button"
            class="btn btn-primary"
            (click)="updateImage()"
          >
            Salvar
          </button>
        </div>
      </div>
  
      <!-- Postagem -->
  
      <!-- Imagem da Postagem -->
      <div *ngIf="pageElement.typePost">
        <div>
          <div class="row text-center">
            <div class="col">
              <img src="../../assets/arpron/assets/{{pageElement.image}}" class="img-fluid" alt="" />
            </div>
          </div>
  
          <div class="text-center p-5">
              <!-- <input type="file"  /> -->
              <input type="file" id="selectedFile" style="display: none;" (change)="inputFileChange($event)"/>
              <input type="button" value="Imagem" onclick="document.getElementById('selectedFile').click();" />
            </div>
  
            <input
            type="text"
            class="form-control input-content"
            [(ngModel)]="pageElement.caption"
            placeholder="Título"
          />
        </div>
      </div>
      
      <!-- Texto da postagem -->
      <div *ngIf="pageElement.typePost">
        <textarea
          cols="12"
          rows="5"
          class="form-control input-content"
          [(ngModel)]="pageElement.text"
          placeholder="Texto"
        ></textarea>
  
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            (click)="cancel()"
          >
            Fechar
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
            (click)="updateContent()"
          >
            Salvar
          </button>
        </div>
      </div>
      <!-- Postagem fim -->
    </div>
  </div>
  