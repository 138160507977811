import { Component, OnInit, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-construction',
  templateUrl: './construction.component.html',
  animations: [
    trigger('pageLoad', [
      state('ready', style({opacity: 1})),
      transition('void => ready', [
      style({opacity: 0}),
      animate('600ms ease-in-out')
      ])
    ])
  ],
  styleUrls: ['./construction.component.scss']
})
export class ConstructionComponent implements OnInit {
  pageState = 'ready';


  constructor() { }

  ngOnInit(): void {
    
  }

}
