<div class="container">
    <div>
 
      <!-- Postagem -->
  
      <!-- Imagem da Postagem -->
      <div>
        <div>
          <div class="row text-center">
            <div class="col">
              <img src="../../assets/arpron/assets/{{pageElement.image}}" *ngIf="pageElement.image !== ''" class="img-fluid" />
              <img src="../../assets/arpron/assets/empty.png" class="img-fluid" alt="" *ngIf="pageElement.image === ''"/>
            </div>
          </div>
          <!-- <h4 class="text-center">Insira uma imagem</h4> -->
  
          <div class="text-center p-5">
              <input type="file" id="selectedFile" style="display: none;" (change)="inputFileChange($event)"/>
              <input type="button" value="Imagem" onclick="document.getElementById('selectedFile').click();" />
            </div>
  
  
          <!-- <h4 class="text-center p-3">Insira um Título</h4> -->
          <input
            type="text"
            class="form-control input-content"
            [(ngModel)]="pageElement.caption"
            placeholder="Título"
          />
        </div>
      </div>
      
      <!-- Texto da postagem -->
      <div>
        <!-- <h4 class="text-center">Insira um texto</h4> -->
        <textarea
          cols="12"
          rows="5"
          class="form-control input-content"
          [(ngModel)]="pageElement.text"
          placeholder="Texto"
        ></textarea>
  
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            (click)="cancel()"
          >
            Fechar
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
            (click)="saveNew()"
          >
            Salvar
          </button>
        </div>
      </div>
      <!-- Postagem fim -->
    </div>
  </div>
  