import { Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location} from '@angular/common';

import { HttpService } from './../services-module/http.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {

  
  id
  page

  pageElement = null

  constructor(
    private service: HttpService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.page = this.route.snapshot.paramMap.get('page');
    
    this.service.readID(`${this.page}/${this.id}`).subscribe(res =>
    this.pageElement = res
    )
  }

  updateContent(){
    this.service.updateData(`${this.page}/${this.id}`,this.pageElement)
    .subscribe(res => {
      this.router.navigateByUrl('refresh'); 
    });
  }

  updateImage() {
    this.service.updateData(`${this.page}/${this.id}`, this.pageElement)
    .subscribe(res => {
      this.router.navigateByUrl('refresh')
    });
  }

  inputFileChange(event) {
    if (event.target.files && event.target.files[0]) {
      const foto = event.target.files[0];
      this.pageElement.image = foto.name

      const formData = new FormData();
      formData.append('file', foto);

      this.service.uploadPhoto(formData)
      
    }
  }

  cancel(){
    this.router.navigateByUrl('refresh')
  }

  back(){
    this.location.back();
  }


}
