import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {

  slideConfig2 = {
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 1000,
    infinite: true,
    autoplay: true,
    arrows: false,
    vertical: true
    // asNavFor: ".customer-carousel-caption"
  };

  slickCaption = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    // "nextArrow": '#next',
    // "prevArrow": '.prev',
    fade: false,
    // asNavFor: ".customer-carousel",
    centerMode: false,
    dots: false,
    focusOnSelect: true,
  };

  slides = [
    {
      img: "../../assets/arpron/assets/person.png",
      owner: "João",
      company: "General Electric",
      testimonial:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, voluptate!",
    },
    {
      img: "../../assets/arpron/assets/person.png",
      owner: "Bruna",
      company: "General Motors",
      testimonial:
        "Lorem, ipsum dolor sit amet consectetur",
    },
    {
      img: "../../assets/arpron/assets/person.png",
      owner: "Patrícia",
      company: "Apple",
      testimonial:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit",
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
