import { Component,OnInit, HostListener, Input } from '@angular/core';
import { Location } from '@angular/common';
import * as Aos from 'aos'

import { Router } from '@angular/router';
import { HttpService } from './services-module/http.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

  title = 'arpron';
  nav = ''

  edit

  constructor(
    private service: HttpService,
    private router: Router,
    private location: Location
    
  ){

  }

  ngOnInit():void{
    this.edit = this.service.edit
    Aos.init();
  }

 
  @HostListener('window:scroll', ['$event']) onScroll($event){
    let position = $event['currentTarget'].scrollY;
    if (position > 150){
      this.nav = 'fixed-top bg-nav'
    }else{
      this.nav = ''
    }
    
  }

  logEvent($event){
    this.edit = $event
  }

  goToContact(){
    const el = document.getElementById('contact')
    el.scrollIntoView()
  }

  logOut(){
    this.service.edit = false
    this.edit = false
    this.router.navigateByUrl('refresh')
  }


  back(){
    this.location.back()
  }


}
