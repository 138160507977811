<div class="modal-header">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="back()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="text-uppercase">
      <div class="form-group">
        <label for="nome" class="text-white font-weight-bold">Usuário</label>
        <input type="text" class="form-control" id="nome" placeholder="" />
      </div>

      <div class="form-group">
        <label for="password" class="text-white font-weight-bold">Senha</label>
        <input type="password" class="form-control" id="password" placeholder="" />
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="logIn()" data-dismiss="modal" aria-label="Close">
      Entrar
    </button>
  </div>
