import { Component, OnInit, AfterViewInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { HttpService } from './../services-module/http.service';


declare const $:any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  animations: [
    trigger('pageLoad', [
      state('ready', style({opacity: 1})),
      transition('void => ready', [
      style({opacity: 0}),
      animate('600ms ease-in-out')
      ])
    ])
  ],
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {
  pageState='ready'

  projects = null

  element = null

  experience = new Date().getFullYear() - 2003


 introCards = [
    {
      icon: 'flaticon-medal',
      title: `${this.experience} anos de experiência`,
      body: 'Conte com a ajuda de uma empresa com expertise e experiência na gestão de diveros projetos'
    },
    {
      icon: 'flaticon-wrench',
      title: 'Mão de obra especializada',
      body: 'Conte com profissionais altamente capacitados, tanto para execução, como para manutenção de sistemas de controle de temperatura'
    },
    {
      icon: 'flaticon-work-time',
      title: 'Eficiência e qualidade',
      body: 'Nosso método de gestão e processo único de implementação garante a rapidez e qualidade de nossos projetos'
    },
    {
      icon: 'flaticon-green-earth',
      title: 'Proteção ao meio ambiente',
      body: 'Trabalhamos com certificação do Ibama, por que não basta melhorar a qualidade do seu ambiente. Queremos melhorar o meio ambiente'
    }
  ];

 

  slideCards = {
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 1000,
    infinite: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          dots: true
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          dots: true
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          dots: true
        },
      }
    ],
  };


  constructor(private service: HttpService) { }

  ngOnInit(): void {
    this.counter();
    this.service.url = ''
  }

  
  ngAfterViewInit(): void {}

  goToContact(){
    const el = document.getElementById('contact')
    el.scrollIntoView()
  }

  counter(){
    $('.counter').countUp({
      'time': 10000,
      'delay': 10
    });
  }

  changeID(id){
    this.element = id
  }

}
