import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.scss']
})
export class BrandsComponent implements OnInit {

  slides = [
    { slide: 'lg.png' },
    { slide: 'york.png' },
    { slide: 'trane.png' },
    { slide: 'komeco.png' },
    { slide: 'elgin.png' },
    { slide: 'electrolux.png' },
    { slide: 'carrier.png' },
    { slide: 'fujitsu.png' },
    { slide: 'hitachi.png' },
    { slide: 'springer.png' },
    { slide: 'agratto.png' },
    { slide: 'comfee.png' },
    { slide: 'daikin.png' },



  ]

  slideConfig = {
    slidesToShow: 5,
    slidesToScroll: 1,
    speed: 1000,
    infinite: true,
    arrows: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2
        },
      }
    ],
  }
  constructor() { }

  ngOnInit(): void {
  }

}
