<div [@pageLoad]="pageState">
  <div class="py-5 text-center text-white h-60 align-items-center d-flex">
    <div class="container py-5">
      <div class="row">
        <div class="mx-auto col-lg-8 col-md-10">
          <h1 class="display-3 mb-4">Quem somos</h1>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <article class="p-5 lead">
      <div class="row">
        <div class="col-lg-6 order-2 image-1">
        </div>
        <div class="col-lg-6 d-flex flex-column justify-content-center">
          <h2 class="pt-5"
          data-aos="fade-in" data-aos-delay="100" data-aos-duration="1100"
          >Nossa história</h2>
          <p class="pb-5">
            Somos privilegiados por Deus, pois temos profissionais altamente qualificados dentro de uma família estruturada. Já atuamos no ramo de ar condicionado e refrigeração há mais de 6 anos, atuando sempre por outras empresas altamente conceituadas dentro do seguimento, a partir daí e com uma visão refinada das reais necessidades do mercado, nasce O GRUPO ARPRON (Ar de Ar condicionado e Pron de Prontidão), uma nova Empresa Mas com muita experiência e compromisso, com todos os clientes, e estamos conseguindo provar que estamos no caminho certo recebendo de volta a satisfação de nossos clientes, conquistando uma estrutura sólida, hoje contamos com uma central de atendimento e um departamento administrativo, carro oficina, carro comercial para atendimento ao cliente, ampla oficina em terreno próprio para acompanhamento técnico de nossos produtos e melhor funcionamento no atendimento ao cliente.
            Estamos empenhados e atrelados a parceiros e fornecedores de confiança e também compromissados com os clientes, preparados para atender as expectativas dinâmicas de nossos clientes, com métodos de trabalho inovadores e funcionais tais como plantão 24h para refrigeração, manutenção corretiva emergencial e programada, compromisso com entregas dos serviços, e sempre buscando melhoria continua.
            Nas necessidades dos nossos clientes, toda equipe é mobilizada, para satisfazer em tempo, qualidade e satisfação.
          </p>
          <img src="../../assets/arpron/assets/apron-interior.jpg" alt="" class="img-fluid d-lg-none">
        </div>

      </div>
      <div class="row">
        <div class="col-lg-6 image-2">
        </div>
        <div class="col-lg-6 d-flex flex-column justify-content-center">
          <h2 class="pt-5"
          data-aos="fade-in" data-aos-delay="100" data-aos-duration="1100"
          >Politica de Qualidade</h2>
          <p class="pb-5">
            O GRUPO ARPRON, empresa do seguimento de ar condicionado e refrigeração, busca obter a satisfação dos clientes, atendendo por completo suas necessidades e expectativas, por meio do fornecimento de produtos e serviços na área analítica, comprometendo toda empresa na realização e busca por melhorias do nosso sistema de gestão de qualidade, em um ambiente de trabalho que promova a satisfação e a realização de todos os colaboradores.
          </p>
          <img src="../../assets/arpron/assets/workers.jpg" alt="" class="img-fluid d-lg-none">
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 order-2 image-3">
        </div>
        <div class="col-lg-6 d-flex flex-column justify-content-center">
          <h2 class="pt-5"
          data-aos="fade-in" data-aos-delay="100" data-aos-duration="1100"
          >Politica de Meio Ambiente</h2>
          <p class="pb-5">
            O GRUPO ARPRON que atua a nível nacional no ramo de ar condicionado e refrigeração, tem um compromisso permanente com a proteção ao meio ambiente, que através de uma conduta pró-ativa, busca uma melhoria continua de seus processos visando a minimização dos impactos ambientais, para isso os seguintes preceitos.
          </p>
          <img src="../../assets/arpron/assets/interior-air-conditioners.jpg" alt="" class="img-fluid d-lg-none">
        </div>

      </div>
    </article>
    <app-brands></app-brands>
    <!-- <app-partners></app-partners> -->
    <app-contact></app-contact>
  </div>
</div>
