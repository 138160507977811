import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { HttpService } from './../services-module/http.service';


@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {

  page

  pageElement = {
    typePost: true,
    typeImage: false,
    link: '',
    image: '',
    text: '',
    caption: ''
  }

  constructor(
    private service: HttpService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.page = this.route.snapshot.paramMap.get('page')
  }

  inputFileChange(event) {


    if (event.target.files && event.target.files[0]) {
      const foto = event.target.files[0];
      this.pageElement.image = foto.name

      const formData = new FormData();
      formData.append('foto', foto);

      this.service.uploadPhoto(formData)

    }
  }

  saveNew() {
    this.service.createData(this.page, this.pageElement).subscribe(res => {
      console.log('Produto adicionado');
      this.router.navigateByUrl('refresh')
    });
  }

  cancel() {
    this.location.back();
  }

}
