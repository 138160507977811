import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  edit = false

  url

  private readonly Api = `${environment.API}`

  constructor(private http: HttpClient) { }

  loadData(page){
    return this.http.get(`${this.Api}${page}`);
  }

  createData(page, data){
    return this.http.post(`${this.Api}${page}`, data);
  }

  updateData(id, request){
    return this.http.put(`${this.Api}${id}`, request);
  }
  
  readID(id){
    return this.http.get(`${this.Api}${id}`);
  }

  remove(id){
    return this.http.delete(`${this.Api}${id}`);
  }

  uploadPhoto(formData){
    this.http.post('./upload.php', formData)
    .subscribe(resposta => console.log('Upload ok.'));
  }

}
