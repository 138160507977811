import { Component, OnInit } from '@angular/core';

import { HttpService } from '../services-module/http.service';

@Component({
  selector: 'app-sale',
  templateUrl: './sale.component.html',
  styleUrls: ['./sale.component.scss']
})
export class SaleComponent implements OnInit {

  edit = false
  page = 'sale'
  pageElements = null

  constructor(
    private service: HttpService
  ) { }

  ngOnInit(): void {
    this.edit = this.service.edit
    this.service.url = ''
    this.service.loadData(this.page)
      .subscribe(res =>
        this.pageElements = res
      )
  }
}
