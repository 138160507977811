import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { ConstructionComponent } from './construction/construction.component';
import { AboutComponent } from './about/about.component';
import { FacilitiesComponent } from './facilities/facilities.component';
import { ProductsComponent } from './products/products.component';
import { EditComponent } from './edit/edit.component';
import { RefreshComponent } from './refresh/refresh.component';
import { AddComponent } from './add/add.component';
import { RemoveComponent } from './remove/remove.component';
import { WorkWithUsComponent } from './work-with-us/work-with-us.component';




const routes: Routes = [
  {
    path: '', component: HomeComponent,
    children: [
      { path: 'add/:page', component: AddComponent },
      { path: 'edit/:page', component: EditComponent },
      { path: 'edit/:page/:id', component: EditComponent },
      { path: 'remove/:page/:id', component: RemoveComponent },

    ]
  },
  { path: 'building', component: ConstructionComponent },
  { path: 'quem-somos', component: AboutComponent },
  {
    path: 'servicos', component: FacilitiesComponent,
    children: [
      { path: 'add/:page', component: AddComponent },
      { path: 'remove/:page/:id', component: RemoveComponent },
      { path: 'edit/:page/:id', component: EditComponent }
    ]
  },
  {
    path: 'produtos', component: ProductsComponent,
    children: [
      { path: 'add/:page', component: AddComponent },
      { path: 'remove/:page/:id', component: RemoveComponent },
      { path: 'edit/:page/:id', component: EditComponent }
    ]
  },
  { path: 'refresh', component: RefreshComponent },
  { path: 'trabalhe-conosco', component: WorkWithUsComponent },

  { path: '**', component: HomeComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
