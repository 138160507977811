<div class="container">
    <div>
 
      <!-- Postagem -->
  
      <!-- Imagem da Postagem -->
      <div>
        <div *ngIf="pageElement" >
          <div class="row text-center">
            <div class="col">
              <img src="../../assets/arpron/assets/{{pageElement.image}}" class="img-fluid" />
            </div>
          </div>
  
          <h4 class="text-center p-3 font-weight-bold text-white">{{ pageElement.caption }}</h4>
        </div>
      </div>
      
      <!-- Texto da postagem -->
  
            <div class="modal-footer d-flex flex-column" *ngIf="!confirm && !feedback">
              <div class="py-1">
                <button
                  type="button"
                  class="btn btn-secondary m-2"
                  data-dismiss="modal"
                  (click)="cancel()"
                >
                  Cancelar
                </button>
                <button type="button" class="btn btn-primary m-2" (click)="deletePost()">
                  Deletar
                </button>
              </div>
              </div>
              <div class="modal-footer d-flex flex-column" *ngIf="confirm && !feedback">
                <div class="confirmation text-white">
                  <h5>Tem certeza que deseja excluir?</h5>
                </div>
                <div class="py-1">
                  <button
                    type="button"
                    class="btn btn-secondary m-2"
                    data-dismiss="modal"
                    (click)="cancel()"
                  >
                    Cancelar
                  </button>
                  <button type="button" class="btn btn-danger m-2" (click)="confirmDelete()">
                    Excluir
                  </button>
                </div>
              </div>
            
              <div class="ac-feedback" *ngIf="feedback" >
            
                <div class="confirmation text-center ac-sucesso" *ngIf="success">
                  <div class="">
                    <h5>Atualização feita com sucesso</h5>
                  </div>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                    (click)="back()"
                  >
                    OK
                  </button>
                </div>
              
                <div class="confirmation text-right ac-erro" *ngIf="error">
                  <div class="">
                    <h5>Houve uma falha. Tente novamente</h5>
                  </div>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                    (click)="cancel()"
                  >
                    OK
                  </button>
                  <div class="ac-falha"></div>
                </div>
              </div>
            
      <!-- Postagem fim -->
    </div>
  </div>
  
