<div class="py-5 on-sale" *ngIf="(pageElements && pageElements.length) || edit">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <span>Confira nossas</span>
          <h2 class="py-4">Promoções</h2>
          <div class="row py-4" *ngFor="let item of pageElements" [class.editable]='edit'>
              <div class="col-12 col-md-4">
                  <img src="../../assets/arpron/assets/{{ item.image }}" alt="" class="img-fluid">
              </div>
              <div class="col-12 col-md-8">
                <h3>{{item.caption}}</h3>
                <p>{{item.text}}</p>
                <div class="d-flex justify-content-around editable-buttons">
                  <a
                  class="btn btn-primary text-uppercase text-white"
                  [routerLink]="['/edit', 'sale', item.id]"
                  data-toggle="modal"
                  data-target="#editModal"
                  >Editar</a
                >
                <a
                class="btn btn-danger text-uppercase text-white"
                [routerLink]="['remove', 'sale', item.id]"
                data-toggle="modal"
                data-target="#editModal"
                >Remover</a>
                </div>
              </div>
          </div>
          
        </div>
        <div class="row py-3" *ngIf="edit" >
          <div class="col text-center">
            <a
              class="btn btn-primary text-white btn-rounded"
              data-toggle="modal"
              data-target="#editModal"
              [routerLink]="['add', 'sale']"
              >
                  Adicionar Promoção
                </a
            >
          </div>
        </div>
      </div>
    </div>
  </div>